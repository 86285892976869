<template>
  <div>
    <div id="datepicker_block">
      <ion-datetime-button datetime="datetime_date" style="display: none;" />
      <ion-modal :keep-contents-mounted="true" :is-open="showDate" @did-dismiss="showDate = false">
        <ion-datetime
          id="datetime_date"
          ref="datePicker"
          presentation="date"
          :min="useDateFormat(new Date(), 'YYYY-MM-DD').value"
          cancel-text=""
          @ion-change="addNewDate"
        />
        <!-- Should be $el.cancel, not $el.confirm for some reason .confirm fires a lot of events -->
      </ion-modal>
    </div>
    <div id="no_repeat_block">
      <ion-list :inset="false">
        <ion-item lines="none">
          <ion-grid>
            <ion-row class="ion-align-items-top">
              <ion-col>Date</ion-col>
              <ion-col style="text-align:right">Times</ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item v-if="!notPastSpecificDays.length" lines="none" class="ion-text-center">
          <ion-label><ion-note>No timeslots added</ion-note></ion-label>
        </ion-item>
        <ion-item v-for="day in notPastSpecificDays" :key="day" lines="none">
          <ion-grid>
            <ion-row class="ion-align-items-top" :data-testid="`schedule-${day.when}`">
              <ion-col size="6">
                <ion-chip v-if="day.when">
                  <ion-label>{{ useDateFormat(day.when, 'MMM D ddd').value }}</ion-label>
                  <ion-icon :icon="close" @click="removeDate(day, day.when)" />
                </ion-chip>
                <p style="padding-left: 1em; width: 100%">
                  <ion-select
                    v-model="day.cascadingMode" interface="action-sheet" label="Advertise"
                    value="no" label-placement="floating"
                  >
                    <ion-select-option value="none">All times</ion-select-option>
                    <ion-select-option value="earliest">Earliest time first</ion-select-option>
                    <ion-select-option value="latest">Latest time first</ion-select-option>
                  </ion-select>
                  <ion-note v-if="day.cascadingMode == 'none'">
                    All timeslots for the day will be available for booking.
                  </ion-note>
                  <ion-note v-if="day.cascadingMode == 'earliest'">
                    Only the earliest timeslot of the day will be available. Upon booking, the next available timeslot will be opened.
                  </ion-note>
                  <ion-note v-if="day.cascadingMode == 'latest'">
                    Only the latest timeslot of the day will be available for booking. Upon booking, the previous available timeslot will be opened.
                  </ion-note>
                </p>
              </ion-col>
              <schedule-time-slots
                :day="day" :all-days="notPastSpecificDays" :lesson-type="lessonType"
                @show-time-picker="() => showTimePicker(day)"
                @copy-day-times="(option) => copyDayTimes(option, day, notPastSpecificDays)"
                @remove-opening="(time) => removeTime(day, time)"
              />
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-button
          expand="block" fill="clear" color="success"
          @click="showDate = true"
        >
          <ion-icon slot="start" :icon="add" /> Add Date
        </ion-button>
      </ion-list>
    </div>
  </div>
</template>

<script setup>
import {
  IonButton, IonChip, IonCol, IonDatetime, IonDatetimeButton, IonGrid,
  IonIcon, IonItem, IonLabel, IonList,
  IonModal, IonNote, IonRow, IonSelect, IonSelectOption,
} from '@ionic/vue'
import { add, close } from 'ionicons/icons'
import { addDays, formatDate } from 'date-fns'
import { computed, reactive, ref } from 'vue'
import { helpers } from '@vuelidate/validators'
import { useDateFormat } from '@vueuse/core'
import { useVuelidate } from '@vuelidate/core'

import ScheduleTimeSlots from '@/views/instructors/schedules/ScheduleTimeSlots.vue'
import { showTimePickerActionSheet } from '@/4_features/TimePicker'

import { findOverlaps } from '@/6_shared/utils/formattingLessons'

const props = defineProps({
  lessonType: { required: true, type: Object },
})

const schedule = props.lessonType.schedule

for (const day of schedule.dates.days) {
  if (!day.cascadingMode) Object.assign(day, { cascadingMode: 'none' }) // none, earliest, latest
}

const rules = {
  repeats: {
    noOverlap: helpers.withMessage(
      function () {
        const overlaps = findOverlaps(props.lessonType)
        if (overlaps.length === 0) return true
        return `Please fix overlapping start times:<br>${overlaps.slice(0, 10).join('<br>')}`
      },
      function () {
        return findOverlaps(props.lessonType).length === 0
      },
    ),
  },
}
const v$ = useVuelidate(rules, schedule)
v$.value.$touch()

const showDate = ref(false)
const datePicker = ref()

const notPastSpecificDays = computed(() => {
  const yesterday = formatDate(addDays(new Date(), -1), 'yyyy-MM-dd')
  return schedule.dates.days.filter(day => day.when >= yesterday)
})

function removeTime(day, timeToRemove) {
  day.times = day.times.filter(time => time !== timeToRemove)
  v$.value.$touch()
}

function addNewDate(event) {
  const date = event.detail.value.split('T')[0] // extract iso date from 2024-05-02T17:04:00
  const newDay = reactive({ when: date, times: [], cascadingMode: 'none' })
  schedule.dates.days.push(newDay)
  v$.value.$touch()
  datePicker.value.$el.cancel(true)
}

async function showTimePicker(day) {
  await showTimePickerActionSheet((time) => {
    day.times = [...new Set([...day.times, time])].sort()
    v$.value.$touch()
  })
}

function copyDayTimes(option, day, days) {
  const copyDay = days.find(d => d.when === option)
  day.times = [...new Set([...day.times, ...copyDay.times])].sort()
  day.cascadingMode = copyDay.cascadingMode
  v$.value.$touch()
}

function removeDate(day, date) {
  schedule.dates.days = schedule.dates.days.filter(d => d.when !== date)
  v$.value.$touch()
}
</script>

<style scoped>
h4 {
  font-weight: normal;
}

ion-row {
  border-bottom: 1px solid var(--ion-color-light-shade)
}
</style>
