import { escape } from 'lodash'

function sanitizeText(text) {
  return escape(text)
}

export function formatText(text) {
  // Sanitize the entire text first
  const safeText = sanitizeText(text)

  // Function to apply inline formatting (bold, italics, and line breaks)
  function applyInlineFormatting(text) {
    // Bold: **text**
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // Italics: *text*
    text = text.replace(/\*((?!\*).+?)\*/g, '<em>$1</em>')
    // make links clickable and open in new page
    text = text.replace(/(https?:\/\/[^\s]+)/g, url =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
    )
    return text
  }

  // Apply inline formatting to the entire text
  const formattedText = applyInlineFormatting(safeText)

  // Split the text into blocks
  const blocks = formattedText.split(/\n{2,}/)

  // Format each block (paragraph or list)
  const formattedBlocks = blocks.map((block) => {
    const lines = block.split('\n')

    // Check if it's a numbered list
    if (lines.slice(1).every(line => /^\s*\d+\./.test(line))) {
      return `<p>${lines[0]}</p><ol>`
        + lines.slice(1).map(line => `<li>${line.replace(/^\s*\d+\.\s*/, '')}</li>`).join('')
        + '</ol>'
    }

    // Check if it's a bullet point list (- or *)
    if (lines.slice(1).every(line => /^\s*[-*]/.test(line))) {
      return `<p>${lines[0]}</p><ul>`
        + lines.slice(1).map(line => `<li>${line.replace(/^\s*[-*]\s*/, '')}</li>`).join('')
        + '</ul>'
    }

    // It's a regular paragraph or multiple lines
    return `<p>${lines.join('<br>')}</p>`
  })

  return formattedBlocks.join('') // Join the formatted blocks
    .replace('<ol></ol>', '') // remove empty <ol> tags
}
