<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ instructor?.name }}</ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding">
      <template v-if="instructor">
        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-align-items-center" size="auto">
              <user-avatar :url="instructor.photoURL" :size="100" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <!-- <ion-label><h1>{{ instructor.name }} <img src="https://www.pgaofalberta.com/images/ui/pga-logo.png" alt="placeholder" width="50"></h1></ion-label> -->
              <div v-if="instructor.about">
                <ion-label><strong>About</strong></ion-label>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div data-testid="about-block" style="margin-top: -1em" v-html="formatText(instructor.about)" />
                <hr style="border-bottom: 1px solid rgb(215, 216, 218)">
              </div>

              <div v-if="instructor.specializations" class="ion-margin-top">
                <ion-label><strong>Specializations</strong></ion-label>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div style="margin-top: -1em" v-html="formatText(instructor.specializations)" />
                <hr style="border-bottom: 1px solid rgb(215, 216, 218)">
              </div>
              <!-- <p class="ion-text-center ion-no-margin">
                <ion-button id="show-more" size="small" fill="clear">learn more</ion-button>
              </p> -->
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- <ion-segment v-model="subPage" mode="md">
          <ion-segment-button value="pricing"><ion-label>Pricing</ion-label></ion-segment-button>
          <ion-segment-button value="reviews" disabled="true">
            <ion-label>
              Reviews<ion-badge>100</ion-badge>
            </ion-label>
          </ion-segment-button>
        </ion-segment> -->

        <!-- <ion-modal id="info-modal" ref="infoModal" trigger="show-more">
          <div class="ion-padding">
            <h2>About</h2>
            <p>Hi, my name is Jake Smith and I am currently a PGA of Alberta Professional located at The Ranch Golf & Country Club. I strive to create a relaxing and comfortable environment where players of all skill levels can achieve their fullest potential. After 5 years as a professional, I have the experience and knowledge to bring your golf game to the next level!</p>

            <ion-list>
              <ion-item><h3>Block Style 1</h3></ion-item>
              <ion-item><p>Text will be provided by instructor</p></ion-item>
            </ion-list>
            <ion-card>
              <ion-card-header>
                <ion-card-title>Block Style 2</ion-card-title>
                <ion-card-subtitle>
                  TODO: optimize for empty profile
                </ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <p>Not all instructors will have certificates or filled out profile. We need to account for that.</p>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-modal> -->
        <div v-if="subPage == 'pricing'">
          <lesson-type-card
            v-for="lessonType in validLessonTypes"
            :key="lessonType" style="margin-left: -1em; margin-right: -1em"
            :editable="false" :lesson-type="lessonType" :schedules="[]"
          />
        </div>
      </template>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                expand="block" fill="outline" color="success"
                :router-link="
                  currentUser ? {name: 'chat', params: {
                    chatId: [currentUser.uid, instructor?.id].sort().join('---')
                  }} : {query: {show: 'login'}}"
              >
                <ion-icon slot="start" :icon="chatbubblesOutline" />
                &nbsp;Chat
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block" color="success"
                :router-link="{
                  name: currentUser ? 'bookLesson' : 'guestBookLesson',
                  params: {instructorId: instructor?.id}
                }"
              >
                <ion-icon slot="start" :icon="calendarOutline" />
                &nbsp;Book Lesson
              </ion-button>
            </ion-col>
          </ion-row>
          <package-block-for-golfer v-if="instructor" :instructor="instructor" />
          <ion-row v-if="currentUser">
            <ion-col>
              <purchased-packages-block :display-book-button="false" :current-instructor="instructor" />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter,
  IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonRow, IonTitle, IonToolbar,
} from '@ionic/vue'
import { calendarOutline, chatbubblesOutline } from 'ionicons/icons'
import { computed, ref } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'

import LessonTypeCard from '@/3_widgets/LessonTypeCard.vue'
import PackageBlockForGolfer from '@/3_widgets/packages/PackageBlockForGolfer.vue'
import PurchasedPackagesBlock from '@/3_widgets/packages/PurchasedPackagesBlock.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { formatText } from '@/6_shared/utils/formatText'
import { instructorRef } from '@/state'

const currentUser = useCurrentUser()

const props = defineProps({
  instructorId: { type: String, required: true },
})
const instructor = useDocument(instructorRef(props.instructorId))

const subPage = ref('pricing')

const validLessonTypes = computed(() => {
  return instructor.value?.instructor.lessonTypes
})
</script>

<style scoped>
ion-modal#info-modal {
  --width: fit-content;
  --max-width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --max-height: 90%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
</style>
