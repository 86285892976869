<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Find Instructor</ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <ion-list data-testid="instructors-filters">
        <ion-item lines="none">
          <ion-select
            v-model="booking.city" interface="action-sheet" label="City"
            label-placement="fixed"
            multiple="false"
          >
            <ion-select-option
              v-for="(count, city) in instructorsByCityCounts"
              :key="city"
              :value="city"
            >
              {{ city }} ({{ count }})
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="pricingTypesWithCounts.length" lines="none">
          <ion-select
            v-model="booking.pricingType" interface="action-sheet" label="Filter"
            label-placement="fixed"
            multiple="false"
          >
            <ion-select-option
              v-for="(pricingType) in pricingTypesWithCounts"
              :key="pricingType.key"
              :value="pricingType.key"
            >
              {{ pricingType.name }} ({{ pricingType.count }})
            </ion-select-option>
          </ion-select>
        </ion-item>

        <!-- <ion-item>
          <ion-select
            v-model="when" interface="popover" label="When"
            disabled="true"
          >
            <ion-select-option value="any_time">Any Time</ion-select-option>
            <ion-select-option value="specific_days">Specific Days</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-show="when == 'specific_days'">
          <ion-datetime
            presentation="date" :multiple="true" :value="['2022-06-03', '2022-06-13', '2022-06-29']"
          />
        </ion-item> -->
      </ion-list>
      <br>
      <error-message
        v-if="sortedInstructors.length == 0"
        :title="`No ${ALL_PRICING_TYPES[booking.pricingType].name} Instructors Available in ${booking.city}`"
      />
      <transition-group v-if="sortedInstructors.length" name="slide" tag="div">
        <ion-item
          v-for="instructor in sortedInstructors" :key="instructor.id" :button="true"
          :detail="false" lines="full"
        >
          <instructor-card
            :instructor="instructor"
            :filter="{ pricingType: booking.pricingType, location: booking.city }"
            :liked="user.student.likedUsers[instructor.id]"
            @like:instructor="user.student.fn.likeUser($event)"
          />
        </ion-item>
      </transition-group>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonSelect,
  IonSelectOption, IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCollection } from 'vuefire'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import InstructorCard from '@/views/golfers/lessons/InstructorCard.vue'
import { InstructorsConverter } from '@/5_entities/instructors/Instructor'
import { LessonsRepository } from '@/data/Lesson'
import { useUserStore } from '@/stores/user'

const lessonsRepo = new LessonsRepository()
const user = useUserStore()
const route = useRoute()
const router = useRouter()

// const when = ref('any_time')
const booking = reactive({
  pricingType: 'adult',
  city: route.query.city || 'Edmonton',
})

watch(booking, () => {
  router.replace({ query: { ...route.query, city: booking.city }})
})

const availableInstructors = useCollection(computed(() =>
  lessonsRepo.listLessonTypes().withConverter(InstructorsConverter),
), { ssrKey: 'availableInstructors' })

const filteredByCity = computed(() => {
  return availableInstructors.value
    .filter(instructor => instructor.cities().includes(booking.city))

  // liked instructors first, then by name
})

const filteredByPricingType = computed(() => {
  let result = filteredByCity.value.filter(instructor => instructor.teaches(booking.pricingType))
  const ADMIN_STUDENT_EMAILS = ['hello@ildar.ca', 'chrisotto15@gmail.com', 'nickdesanko@swingmatch.ca']
  if (!ADMIN_STUDENT_EMAILS.includes(user.userData()?.email)) {
    // hide Ildar Instructor from the list for everyone except ADMIN STUDENTS
    result = result.filter(instructor => instructor.email !== 'ildar.abdulin@gmail.com')
  }
  return result
})

const sortedInstructors = computed(() => {
  return [...filteredByPricingType.value].sort((a, b) => {
    const aLiked = user.student.likedUsers[a.id]
    const bLiked = user.student.likedUsers[b.id]

    if (aLiked && !bLiked) return -1
    if (!aLiked && bLiked) return 1
    return a.name.localeCompare(b.name)
  })
})

const instructorsByCityCounts = computed(() => {
  return availableInstructors.value.reduce((counts, instructor) => {
    instructor.cities().forEach(city => counts[city] = (counts[city] || 0) + 1)
    return counts
  }, {})
})

const pricingTypesWithCounts = computed(() => {
  return Object.entries(ALL_PRICING_TYPES).map(([key, pricingType]) => {
    const count = filteredByCity.value.filter(instructor => instructor.teaches(key)).length
    if (count === 0) return null
    return { key, name: pricingType.name, count }
  }).filter(Boolean)
})
</script>

<style scoped>
.slide-enter, .slide-leave-to {
  transform: translateY(0);
}
.slide-move {
  transition: transform 0.5s;
}
</style>
