import { doc } from 'firebase/firestore'
import { useFirestore } from 'vuefire'

export const db = useFirestore()
// export const user = useCurrentUser()

// export const userRef = computed(() => user.value && doc(db, `users/${user.value.uid}`))

// export const userDoc = useDocument(userRef)

export const instructorRef = uid => doc(db, `users/${uid}`)
