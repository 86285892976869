<template>
  <ion-grid data-testid="instructor-card">
    <ion-row class="ion-align-items-center">
      <ion-col size="auto" :router-link="instructorLink">
        <user-avatar :url="instructor.photoURL" />
      </ion-col>
      <ion-col :router-link="instructorLink">
        <h4>{{ instructor.name }}</h4>
        <h6>{{ prices.join(', ') }}</h6>
        <ion-note>
          <div v-for="course in golfCourses" :key="course">{{ course }}</div>
        </ion-note>
      </ion-col>
      <ion-col size="auto">
        <ion-icon
          v-if="currentUser" data-testid="instructor-like-button"
          :icon="props.liked ? heart : heartOutline"
          color="danger" size="large" @click="like(instructor.id)"
        />
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import {
  IonCol, IonGrid, IonIcon, IonNote, IonRow,
} from '@ionic/vue'
import { heart, heartOutline } from 'ionicons/icons'
import { computed } from 'vue'
import { useCurrentUser } from 'vuefire'

import UserAvatar from '@/3_widgets/UserAvatar.vue'

const props = defineProps({
  instructor: { required: true, type: Object },
  filter: { default: () => { return {} }, type: Object },
  liked: { required: true, type: Boolean, default: false },
})
const emit = defineEmits(['like:instructor'])

const currentUser = useCurrentUser()
const instructor = props.instructor
const instructorLink = computed(() => {
  return {
    name: (currentUser.value ? 'instructor' : 'guestInstructor'),
    params: { instructorId: props.instructor.id },
  }
})

const golfCourses = computed(() => {
  let result = []
  instructor.instructor.lessonTypes.forEach((lessonType) => {
    const pricingType = lessonType.pricing[props.filter.pricingType]
    if (pricingType) {
      result.push(`${lessonType.location.name}`)
    }
  })
  result = [...new Set(result)]
  return result
})

const prices = computed(() => {
  const result = []
  instructor.instructor.lessonTypes.forEach((lessonType) => {
    const pricingType = lessonType.pricing[props.filter.pricingType]
    if (pricingType) {
      result.push(`$${pricingType.price} / ${lessonType.length}min`)
    }
  })
  return result
})

function like(userId) {
  emit('like:instructor', userId)
}

</script>

<style scoped>
h4 {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
}
</style>
