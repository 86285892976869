<template>
  <div v-if="upcomingLessons.length > 0">
    <ion-card>
      <ion-card-header>
        <ion-card-title>Upcoming Lessons</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list>
          <lesson-item
            v-for="lesson in upcomingLessons" :key="lesson.id" :lesson="lesson"
            :current-user="userStore.is.type"
            :router-link="{name: 'lessonPage', params: {lessonId: lesson.id}}"
          />
        </ion-list>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script setup>
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList } from '@ionic/vue'
import { computed } from 'vue'
import { groupBy } from 'lodash'

import LessonItem from '@/views/golfers/lessons/LessonItem.vue'
import { LessonsRepository } from '@/data/Lesson'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const lessonsRepo = new LessonsRepository()
const lessons = lessonsRepo.getUserLessons()

const upcomingLessons = computed(() => {
  return groupBy(lessons.value, lesson => lesson.stateGroup())['upcoming'] || []
})

</script>
