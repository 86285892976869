<template>
  <ion-card v-if="filteredPurchasedPackages.length">
    <ion-card-header class="ion-no-padding ion-padding-horizontal ion-padding-top">
      <ion-card-title>Lesson Packages</ion-card-title>
    </ion-card-header>
    <ion-card-content class="ion-no-padding ion-padding-horizontal">
      <ion-list style="margin:0">
        <ion-item
          v-for="(pckg, index) in filteredPurchasedPackages" :key="pckg.id" :lines="index == purchasedPackages.length - 1 ? 'none' : 'full'"
          class="ion-no-padding"
        >
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center ion-padding-vertical">
              <ion-col>
                <strong>{{ pckg.lessonsLeft }} of {{ pckg.lessons }}</strong> prepaid lessons left {{ otherPartyName(pckg) }}
              </ion-col>
              <ion-col v-if="displayBookButton && userStore.is.student" size="auto" class="ion-no-padding">
                <ion-button
                  fill="outline" color="success"
                  size="small" style="margin-left: 1em"
                  data-testid="package-book-button"
                  :disabled="pckg.lessonsLeft == 0"
                  @click="bookLesson(pckg)"
                >
                  Book
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonItem, IonList, IonRow } from '@ionic/vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { LessonsRepository } from '@/data/Lesson'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const props = defineProps({
  displayBookButton: { required: false, type: Boolean, default: true },
  currentInstructor: { required: false, type: Object, default: null },
})

const router = useRouter()

const LessonsRepo = new LessonsRepository()
const purchasedPackages = LessonsRepo.getLessonPackages()

const filteredPurchasedPackages = computed(() => {
  if (!props.currentInstructor) return purchasedPackages.value
  return purchasedPackages.value.filter(pckg => pckg.instructor.id === props.currentInstructor.id)
})

function otherPartyName(pckg) {
  if (props.currentInstructor?.id === pckg.instructor.id) return ''
  if (userStore.is.student) return `with ${pckg.instructor.name}`
  if (userStore.is.instructor) return `with ${pckg.student.name}`
}

async function bookLesson(pckg) {
  router.push({ name: 'bookLesson', params: { instructorId: pckg.instructor.id }})
}
</script>
