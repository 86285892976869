import { addDoc, collection, doc, limit,
  or, orderBy, query, serverTimestamp, updateDoc, where,
} from 'firebase/firestore'
import { firestoreDefaultConverter, useCollection, useDocument, useFirestore } from 'vuefire'
import { useDateFormat } from '@vueuse/core'

import { answerRescheduleRequest, cancelLesson, completeLesson,
  confirmLessonByInstructor, createLessonAndAuthorizePayment,
  createPackage, declineLessonByInstructor, requestReschedule, reviewLesson,
} from '@/firebase'
import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const db = useFirestore()
export class Lesson {
  constructor(lessonObj) {
    this.id ||= lessonObj.id
    Object.assign(this, lessonObj)
  }

  toFirestore() {
    return { ...this }
  }

  isPendingConfirmation() {
    return this.state === 'pending_instructor_confirmation'
  }

  isConfirmedByInstructor() {
    return this.state === 'confirmed_by_instructor'
  }

  isDeclinedByInstructor() {
    return this.state === 'declined_by_instructor'
  }

  isPendingCompletion() {
    return this.isConfirmedByInstructor() && useDateFormat(new Date(), 'YYYY-MM-DD').value >= this.date
  }

  isRescheduleRequested() {
    return this.changes && Object.values(this.changes.approvals).some(approval => approval.approved === null)
  }

  isCancelled() {
    return this.state === 'cancelled'
  }

  isCompleted() {
    return this.state === 'completed'
  }

  stateGroup() {
    if (this.state.includes('declined') || this.isCancelled()) return 'cancelled'
    if (this.isCompleted()) return 'completed'
    if (this.state.includes('pending') || this.isRescheduleRequested()) return 'pending'
    if (this.state.includes('confirmed')) return 'upcoming'
    return 'other'
  }

  humanState() {
    if (this.isCancelled()) return 'Cancelled'
    if (this.isCompleted()) return 'Completed'
    if (this.isDeclinedByInstructor()) return 'Declined'
    if (this.isRescheduleRequested()) return 'Reschedule Requested'
    if (this.isPendingConfirmation()) return 'Pending Confirmation'
    if (this.isConfirmedByInstructor()) return 'Confirmed'
  }

  toIcs(currentUserType) {
    const result = {
      start: this.date.split('-').map(Number).concat(this.time.split(':').map(Number)),
      startInputType: 'local',
      duration: { hours: Math.floor(this.length / 60), minutes: this.length % 60 },
      title: `Golf lesson with ${currentUserType === 'student' ? this.instructor.name : this.student.name}`,
      url: `${window.location.origin}/my/lessons/${this.id}`,
      categories: ['Golf lesson'],
      busyStatus: 'BUSY',
      // description: 'Annual 10-kilometer run in Boulder, Colorado',
      // geo: { lat: 40.0095, lon: 105.2669 },
      // status: 'CONFIRMED',
      // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' }
    }

    if (this.location) result.location = `${this.location.name}, ${this.location.address}`

    return result
  }
}

const LessonsConverter = {
  toFirestore: lesson => lesson.toFirestore(),
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    return new Lesson(data)
  },
}

export class LessonsRepository {
  constructor() {
    this.lessonsCollection = collection(db, 'lessons')
    this.userStore = useUserStore()
  }

  newLesson(timeslot, lessonObj) {
    const lesson = {
      state: 'pending_instructor_confirmation',
      ...lessonObj,
    }
    lesson.datetime = timeslot.datetime
    lesson.date = timeslot.date // "2024-05-26"
    lesson.time = timeslot.formattedTime // "09:00"
    lesson.formattedDate = timeslot.formattedDate // "Sun May 26"

    lesson.length = timeslot.length // 40
    lesson.pricing = timeslot.pricing // { key: "adult", name: "Adult", enabled: true, price: 33 }
    lesson.location = timeslot.location // { uuid: "546618f5-bbfb-4e15-8146-081f5c3b4114", city: "Edmonton", type: "public", name: "Cardiff Golf &amp; Country Club", address: "55307 RR 251, Morinville, AB, T8R 1S1", holes: 18, drivingRange: true }

    lesson.instructorId = timeslot.userId // "HdD8YzWpyQeWqDHYuCiw9PBB5wOP"
    lesson.instructor = {
      name: timeslot.instructor.name,
      photoURL: timeslot.instructor.photoURL,
    }

    const student = this.userStore.userData()
    lesson.studentId = student.id // "HdD8YzWpyQeWqDHYuCiw9PBB5wOP"
    lesson.student = {
      name: student.name,
      photoURL: student.photoURL,
    }

    if (timeslot.inviteId) lesson.inviteId = timeslot.inviteId

    return lesson
  }

  async createLessonAndAuthorizePayment(lesson) {
    localStorage.removeItem('inviteId')
    lesson = new Lesson(lesson)
    return createLessonAndAuthorizePayment(lesson.toFirestore())
  }

  getRef(lessonId) {
    return doc(this.lessonsCollection, lessonId).withConverter(LessonsConverter)
  }

  async update(lesson) {
    await updateDoc(this.getRef(lesson.id), lesson.toFirestore())
  }

  async decline(lesson, declineReason) {
    trackEvent('lesson_declined', { reason: declineReason })
    await declineLessonByInstructor({ lesson, declineReason })
  }

  async confirm(lesson) {
    trackEvent('lesson_confirmed')
    return await confirmLessonByInstructor(lesson)
  }

  async requestReschedule(lesson) {
    trackEvent('lesson_reschedule_requested')
    await requestReschedule(lesson)
  }

  async answerRescheduleRequest(lesson, approved) {
    trackEvent('lesson_reschedule_answered', { approved })
    await answerRescheduleRequest({ lesson, approved })
  }

  async cancelLesson(lesson, cancellationReason) {
    trackEvent('lesson_cancelled', { reason: cancellationReason })
    await cancelLesson({ lesson, cancellationReason })
  }

  async completeLesson(lesson) {
    trackEvent('lesson_completed')
    await completeLesson({ lesson })
  }

  async reviewLesson(lesson, review) {
    trackEvent('lesson_reviewed', { rating: review.rating })
    await reviewLesson({ lesson, review })
  }

  get(lessonId) {
    return useDocument(this.getRef(lessonId))
  }

  getUserLessons() {
    const userLessonsRef = query(
      this.lessonsCollection,
      or(
        where('studentId', '==', this.userStore.userData().id),
        where('instructorId', '==', this.userStore.userData().id),
      ),
    )
    return useCollection(userLessonsRef.withConverter(LessonsConverter), { ssrKey: 'user-lessons' })
  }

  async addEvent(lesson, event) {
    event.createdAt = serverTimestamp()
    const eventsCollectionRef = collection(this.getRef(lesson.id), 'events')
    return addDoc(eventsCollectionRef, event)
  }

  getEventsRef(lessonId) {
    const eventsCollectionRef = query(
      collection(this.getRef(lessonId), 'events'),
      orderBy('createdAt', 'desc'),
    )
    return eventsCollectionRef
  }

  listAvailableLessonTypes() {
    return doc(db, 'stats', 'lessonTypes')
  }

  listLessonTypes() {
    return query(
      collection(db, 'users'),
      where('type', '==', 'instructor'),
      limit(100),
    )
  }

  async createPackage(data) {
    return createPackage(data)
  }

  getLessonPackages() {
    const packagesRef = query(
      collection(db, 'packages'),
      or(
        where('studentId', '==', this.userStore.userData().id),
        where('instructorId', '==', this.userStore.userData().id),
      ),
    )
    return useCollection(packagesRef, { ssrKey: 'user-packages' })
  }
}
