import { actionSheetController } from '@ionic/vue'

const START_HOUR = 7
const END_HOUR = 22 // Assuming the last time is 9:45 PM
const MINUTE_INTERVAL = 15
const HOURS_IN_DAY = 12

function injectTimePickerStyles() {
  if (!document.getElementById('time-picker-styles')) {
    const styleElement = document.createElement('style')
    styleElement.id = 'time-picker-styles'
    styleElement.textContent = `
      .time-picker-action-sheet button.time-bold {
        font-weight: bold;
        color: var(--ion-color-medium) !important;
        border-top: 1px solid var(--ion-color-medium) !important;
      }`
    document.head.appendChild(styleElement)
  }
}

function generateTimes() {
  const times = []
  for (let hour = START_HOUR; hour <= END_HOUR; hour++) {
    for (let minute = 0; minute < 60; minute += MINUTE_INTERVAL) {
      const ampm = hour >= 12 ? 'PM' : 'AM'
      const formattedHour = hour > HOURS_IN_DAY ? hour - HOURS_IN_DAY : hour
      const timeString = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`
      times.push({
        text: timeString,
        data: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
        role: 'time',
        cssClass: minute === 0 ? 'time-bold' : '',
      })
    }
  }
  return times
}

export async function showTimePickerActionSheet(callback) {
  injectTimePickerStyles()
  const actionSheet = await actionSheetController.create({
    buttons: [
      ...generateTimes(),
      { text: 'Cancel', role: 'cancel' },
    ],
    cssClass: 'time-picker-action-sheet',
    htmlAttributes: {
      ['data-testid']: 'time-picker-action-sheet',
    },
  })
  await actionSheet.present()
  const { data, role } = await actionSheet.onWillDismiss()
  if (role === 'time') callback(data)
}
