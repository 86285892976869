<template>
  <ion-header v-show="route.meta.title">
    <ion-toolbar>
      <ion-buttons slot="start" :router-link="{name: 'index'}">
        <img height="33" alt="" src="@/assets/logos/logo.svg">
      </ion-buttons>
      <ion-buttons slot="end">
        <slot name="buttons">
          <ion-button id="intercom_custom_launcher" color="success" size="large">
            <ion-icon slot="icon-only" :icon="helpCircleOutline" />
          </ion-button>
        </slot>
      </ion-buttons>

      <ion-title>
        <slot name="title">{{ route.meta?.title }}</slot>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/vue'
import { watchEffect } from 'vue'

import { helpCircleOutline } from 'ionicons/icons'
import { useRoute } from 'vue-router'
import { useTitle } from '@vueuse/core'

const route = useRoute()

watchEffect(() => {
  useTitle(route.meta.title)
})
</script>

<style scoped>
ion-title{
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}
</style>
