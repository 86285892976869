<template>
  <ion-card data-testid="payment-info">
    <ion-card-content v-if="stripeSession">
      <a
        v-if="currentUser === 'student' && receiptUrl" class="ion-float-right" :href="receiptUrl"
        target="_blank" data-testid="receipt-link"
      >
        <ion-icon :icon="receiptOutline" size="large" />
      </a>
      <h2>{{ amountToDisplay }} - <strong>{{ lesson.paymentInfo.packageId ? 'Lesson Package' : (lesson.paymentInfo?.status || 'no info') }}</strong></h2>
      Created: {{ formatDate(stripeSession.created * 1000, 'MMM d, y h:mm a') }}.
      <p v-if="stripeSession.payment_intent?.canceled_at">
        Cancelled: {{ formatDate(stripeSession.payment_intent.canceled_at * 1000, 'MMM d, y h:mm a') }}
        <br>
        Authorized amount was returned.
      </p>
      <p v-if="stripeSession.payment_intent && lesson.paymentInfo?.status == 'authorized'">
        Authorization expires: <strong>{{ authorizationExpires }}</strong>. Instructor will capture payment upon lesson confirmation.
      </p>
      <!-- <ul>
        <li v-for="line in stripeSession.line_items.data" :key="line.id">
          ${{ line.amount_total / 100 }} - {{ line.description }}
        </li>
      </ul> -->
      <!-- TODO: check how it looks with actual refunds -->
      <p v-if="refundAmount && currentUser === 'student'">Refunded: ${{ refundAmount }}</p>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard, IonCardContent, IonIcon,
} from '@ionic/vue'
import { formatDate, formatDistanceToNow } from 'date-fns'
import { computed } from 'vue'
import { db } from '@/firebase'
import { doc } from 'firebase/firestore'
import { receiptOutline } from 'ionicons/icons'
import { useDocument } from 'vuefire'

import { SM } from '@/globals'

const props = defineProps({
  lesson: { required: true, type: Object },
  currentUser: { type: String, required: true },
})

const stripeSessionRef = doc(db, `paymentSessions/${props.lesson.paymentInfo.sessionId}/public/data`)
const { data: stripeSessionDoc } = useDocument(stripeSessionRef)

const stripeSession = computed(() => stripeSessionDoc.value)

const authorizationExpires = computed(() => {
  const captureBefore = stripeSession.value
    .payment_intent
    ?.latest_charge
    ?.payment_method_details
    ?.card
    ?.capture_before
  if (!captureBefore) return
  return formatDistanceToNow(captureBefore * 1000, { addSuffix: true })
})

const receiptUrl = computed(() => stripeSession.value?.payment_intent?.latest_charge?.receipt_url)

const refundAmount = computed(() => {
  const refundAmount = stripeSession.value?.payment_intent?.latest_charge?.amount_refunded
  if (!refundAmount) return
  return (refundAmount / 100).toFixed(2)
})

const amountToDisplay = computed(() => {
  if (!stripeSession.value) return
  if (props.currentUser === 'student') {
    return `Payment: $${(stripeSession.value.amount_total / 100).toFixed(2)}`
  } else if (props.currentUser === 'instructor') {
    return `Earnings: $${(stripeSession.value.amount_total / (1 + SM.bookingFeePercent) * SM.takeHomePercent / 100).toFixed(2)}`
  }
  return ''
})
</script>
