<template>
  <div>
    <div v-show="goodToGo" class="ion-padding">
      <div id="checkout">
        <!-- Checkout will insert the payment form here -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onUnmounted, ref } from 'vue'
import { useScriptTag } from '@vueuse/core'

import { STRIPE_PUBLIC_KEY } from '@/globals'
import { createStripeSession } from '@/firebase'

const props = defineProps({
  goodToGo: { required: true, type: Boolean },
  pricing: { required: true, type: Object },
  timeslot: { required: true, type: Object },
})
const emit = defineEmits(['payment-complete'])

const stripeSession = ref({})
let checkout = null

onUnmounted(() => {
  checkout?.destroy()
})

useScriptTag('https://js.stripe.com/v3/', async () => {
  const stripe = Stripe(STRIPE_PUBLIC_KEY)

  // Initialize Checkout
  checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret: async () => {
      stripeSession.value = (await createStripeSession({ pricing: props.pricing, timeslot: props.timeslot })).data
      return stripeSession.value.clientSecret
    },
    onComplete: () => {
      emit('payment-complete', stripeSession.value)
      checkout.destroy()
      checkout = null
    },
  })

  // Mount Checkout
  checkout.mount('#checkout')
})

</script>
