export const SM = {
  env: (location.hostname.includes('test') ? 'development' : 'production'),
  forcedPlatform: undefined,
  takeHomePercent: 0.91, // must be number, 91%
  bookingFeePercent: 0.065, // must be number, 6.5%
}
if (location.hostname === 'ios.test') SM.forcedPlatform = 'ios'
if (location.hostname === 'android.test') SM.forcedPlatform = 'md'
if (window.innerWidth > 800) SM.forcedPlatform = 'md' // force to desktop on larger screens

// Use the test key for now
// Change to live key when ready
// pk_live_51MEFdqFC8jMPZvbWGE67M2WS118Mp0vTfVPn1CYAlsK44VZtJS4WqZWYREN5BajqlWTnPCimf7XMrpVeGhFjfuHG00FBqTS7Tf
export const STRIPE_PUBLIC_KEY = SM.env === 'development'
  ? 'pk_test_51MEFdqFC8jMPZvbWkzZbJejJ5hUlyXFGwl07koZB8GWUhMDXv8K8PXasUemekzZqVkNmfPZrf8oPjcNn7DNEeazB00dXLOeh2S'
  : 'pk_live_51MEFdqFC8jMPZvbWGE67M2WS118Mp0vTfVPn1CYAlsK44VZtJS4WqZWYREN5BajqlWTnPCimf7XMrpVeGhFjfuHG00FBqTS7Tf'

export function trackEvent(name, metadata = {}) {
  if (window.Intercom) {
    window.Intercom('trackEvent', name, metadata)
  }
}
