<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ $props.title }} ({{ workingSelectedValues.length }})</ion-title>
    </ion-toolbar>
    <ion-toolbar>
      <ion-searchbar @ion-input="searchbarInput($event)" />
    </ion-toolbar>
  </ion-header>

  <ion-content color="light" class="ion-padding">
    <!-- <h1>Custom Location</h1> -->
    <!-- <h1>Select Location</h1> -->
    <ion-list id="modal-list" :inset="false">
      <ion-item v-for="(item, index) in filteredItems" :key="item.uuid" :lines="index === filteredItems.length - 1 ? 'none' : 'full'">
        <ion-checkbox :value="item" :checked="isChecked(item)" @ion-change="checkboxChange($event)">
          {{ item.name }}
        </ion-checkbox>
      </ion-item>
    </ion-list>
    <!-- <ion-button disabled="true" expand="block" size="small">Add New Location</ion-button> -->
  </ion-content>
  <ion-footer>
    <ion-button expand="block" @click="confirmChanges()">Done</ion-button>
  </ion-footer>
</template>

<script>
import {
  IonButton, IonCheckbox, IonContent, IonFooter, IonHeader,
  IonItem, IonList, IonSearchbar, IonTitle, IonToolbar,
} from '@ionic/vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {
    IonButton, IonCheckbox, IonContent, IonHeader, IonFooter, IonItem, IonList, IonTitle, IonSearchbar, IonToolbar,
  },
  props: {
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    mode: {
      type: String,
      default: 'multiple',
    },
    title: {
      type: String,
      default: 'Select Items',
    },
  },
  emits: ['selection-cancel', 'selection-change'],
  setup(props, { emit }) {
    // sort props.items by name, don't modify props.items
    const sortedItems = [...props.items].sort((a, b) => a.name.localeCompare(b.name))
    const isSingle = props.mode === 'single'
    const filteredItems = ref([...sortedItems])
    const workingSelectedValues = ref([...props.selectedItems])

    const isChecked = (value) => {
      return workingSelectedValues.value.find(item => item?.uuid === value.uuid) !== undefined
    }

    const confirmChanges = () => {
      emit('selection-change', workingSelectedValues.value)
    }

    const searchbarInput = (ev) => {
      filterList(ev.target.value)
    }

    /**
       * Update the rendered view with
       * the provided search query. If no
       * query is provided, all data
       * will be rendered.
       */
    const filterList = (searchQuery) => {
      /**
         * If no search query is defined,
         * return all options.
         */
      if (searchQuery === undefined) {
        filteredItems.value = [...sortedItems]
      } else {
        /**
           * Otherwise, normalize the search
           * query and check to see which items
           * contain the search query as a substring.
           */
        const normalizedQuery = searchQuery.toLowerCase()
        filteredItems.value = sortedItems.filter((item) => {
          return item.name.toLowerCase().includes(normalizedQuery)
        })
      }
    }

    const checkboxChange = (ev) => {
      const { checked, value } = ev.detail

      if (isSingle) {
        workingSelectedValues.value = []
      }
      if (checked) {
        workingSelectedValues.value = [...workingSelectedValues.value, value]
      } else {
        workingSelectedValues.value = workingSelectedValues.value.filter(item => item.uuid !== value.uuid)
      }
    }

    return {
      filteredItems,
      workingSelectedValues,
      isChecked,
      confirmChanges,
      searchbarInput,
      checkboxChange,
      isSingle,
    }
  },
})
</script>

<style scoped>
ion-checkbox::part(label) {
  white-space: normal;
  overflow: visible;
}
</style>
