<template>
  <div v-if="invite">
    <h2>Lesson Invite</h2>
    <lesson-card :lesson="invite" :current-user="'student'" :hide="['chat']" />
    <ion-button
      color="success" fill="outline" expand="block"
      @click="acceptInvite"
    >
      Accept Lesson Invite
    </ion-button>
    <ion-button
      color="medium" fill="clear" expand="block"
      @click="declineInvite"
    >
      Decline Lesson Invite
    </ion-button>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { IonButton } from '@ionic/vue'
import { useDocument } from 'vuefire'

import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import { LessonInvitesRepository } from '@/data/LessonInvite'

const router = useRouter()
const route = useRoute()
const lessonInviteRepo = new LessonInvitesRepository()
const invite = ref(null)

onMounted(async () => {
  const inviteId = route.query.inviteId
  if (inviteId) {
    const { promise } = useDocument(lessonInviteRepo.getRef(inviteId), { target: invite })
    promise.value.then(() => {
      if (invite.value === null) router.push({ name: 'insideHome' })
    })
  }
})

async function acceptInvite() {
  console.log('accepting invite')
  router.push({
    name: 'bookLesson',
    params: { instructorId: invite.value.instructorId },
    query: { inviteId: invite.value.id },
  })
}

async function declineInvite() {
  invite.value = null
  localStorage.removeItem('inviteId')
  router.push({ name: 'insideHome' })
}

</script>
