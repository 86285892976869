<template>
  <ion-accordion-group v-if="changes" data-testid="lesson-reschedule-block" :value="status == 'pending' ? 'reschedule-block' : null">
    <ion-accordion value="reschedule-block" data-testid="reschedule-block-accordion">
      <ion-item slot="header" color="light">
        Reschedule Request
        <ion-chip v-if="status == 'approved'" color="success">
          approved <ion-icon :icon="thumbsUp" />
        </ion-chip>
        <ion-chip v-if="status == 'declined'" color="danger">
          declined <ion-icon :icon="thumbsDown" />
        </ion-chip>
        <ion-chip v-if="status == 'pending'" color="primary">
          pending <ion-icon :icon="time" />
        </ion-chip>
      </ion-item>
      <div slot="content" class="ion-padding">
        <ion-grid>
          <ion-row>
            <ion-col style="text-decoration: line-through;">{{ changes.old.formattedDate }}, {{ changes.old.time }}</ion-col>
            <ion-col class="ion-text-right">
              <strong>{{ changes.new.formattedDate }}, {{ changes.new.time }}</strong>
            </ion-col>
          </ion-row>
          <template v-for="userType in ['student', 'instructor']" :key="userType">
            <ion-row class="ion-align-items-center">
              <ion-col>
                <ion-label>{{ lesson[userType].name }}</ion-label>
              </ion-col>
              <ion-col class="ion-text-right">
                <template v-if="changes.approvals[userType].approved != null">
                  <ion-chip v-if="changes.approvals[userType].approved" color="success">
                    {{ changes.requestor.type == userType ? ' requested' : ' approved' }}
                    <ion-icon :icon="thumbsUp" />
                  </ion-chip>
                  <ion-chip v-if="!changes.approvals[userType].approved" color="danger">
                    declined <ion-icon :icon="thumbsDown" />
                  </ion-chip>
                </template>
                <template v-else>
                  <template v-if="currentUser == userType">
                    <ion-button
                      fill="solid" color="success" expand="block"
                      @click="emit('changes:approve')"
                    >
                      <ion-icon slot="start" :icon="thumbsUp" /> Approve
                    </ion-button>
                    <ion-button
                      fill="solid" color="danger" expand="block"
                      @click="emit('changes:decline')"
                    >
                      <ion-icon slot="start" :icon="thumbsDown" /> Decline
                    </ion-button>
                  </template>
                  <template v-else>
                    <ion-chip color="primary">
                      awaiting
                      <ion-icon :icon="time" />
                    </ion-chip>
                  </template>
                </template>
              </ion-col>
            </ion-row>
          </template>
        </ion-grid>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import {
  IonAccordion, IonAccordionGroup, IonButton, IonChip, IonCol,
  IonGrid, IonIcon, IonItem, IonLabel, IonRow,
} from '@ionic/vue'
import { thumbsDown, thumbsUp, time } from 'ionicons/icons'
import { computed } from 'vue'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true },
})

const changes = computed(() => props.lesson.changes)

const status = computed(() => {
  const studentApproved = changes.value.approvals.student.approved
  const instructorApproved = changes.value.approvals.instructor.approved

  if (studentApproved === null || instructorApproved === null) {
    return 'pending'
  } else if (studentApproved && instructorApproved) {
    return 'approved'
  } else {
    return 'declined'
  }
})

const emit = defineEmits(['changes:approve', 'changes:decline'])

</script>
