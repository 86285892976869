<template>
  <ion-page>
    <ion-content
      v-if="invite" color="light" class="ion-padding"
      data-testid="lesson-invite-page"
    >
      <div class="ion-text-center">
        <img width="25%" alt="" src="@/assets/logos/logo.svg">
        <h2 style="font-weight:normal" class="ion-no-margin ion-margin-top">SwingMatch Lesson Invite</h2>
      </div>
      <lesson-card :lesson="invite" :current-user="'student'" :hide="['chat']" />
      <p class="ion-text-center">Sign Up or Sign In to accept the invite</p>
      <!-- <pre>invite: {{ invite }}</pre> -->
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonContent, IonPage } from '@ionic/vue'
import { onMounted, ref } from 'vue'
import { useDocument } from 'vuefire'
import { useRoute } from 'vue-router'

import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import { LessonInvitesRepository } from '@/data/LessonInvite'

const route = useRoute()
const lessonInviteRepo = new LessonInvitesRepository()
const invite = ref(null)

onMounted(async () => {
  const inviteId = route.params.inviteId
  useDocument(lessonInviteRepo.getRef(inviteId), { target: invite })
  localStorage.setItem('inviteId', inviteId)
})
</script>
