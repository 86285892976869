import { doc, setDoc } from 'firebase/firestore'
import { useCurrentUser, useDocument, useFirestore } from 'vuefire'
import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { computed } from 'vue'
import { defineStore } from 'pinia'
import { trackEvent } from '@/globals'

const db = useFirestore()

export const useUserStore = defineStore('user', () => {
  function clearEmptyPricing(pricing) {
    // filter out empty pricing values
    return Object.fromEntries(
      // filter out empty lessonType.pricing values without price and with enabled: false
      Object.entries(pricing).filter(([key, { price, enabled }]) => ALL_PRICING_TYPES[key] && price && enabled),
    )
  }

  function clearEmptySchedule(schedule) {
    // filter out empty schedule values
    const repeatDays = schedule.dates
    repeatDays.days = repeatDays.days.filter(({ times }) => times.length)

    repeatDays.days = repeatDays.days.filter(({ when }) => {
      return new Date() - new Date(when) < 3 * 24 * 60 * 60 * 1000
    })
    repeatDays.days = repeatDays.days.sort((a, b) => a.when.localeCompare(b.when))
    // merge same days, sort and remove duplicates
    repeatDays.days = repeatDays.days.reduce((acc, day) => {
      const when = day.when
      const times = day.times.sort()
      const index = acc.findIndex(({ when: w }) => w === when)
      if (index === -1) {
        acc.push(day)
      } else {
        acc[index].times.push(...times)
        acc[index].times = [...new Set(acc[index].times)].sort()
      }
      return acc
    }, [])
    return schedule
  }

  const user = useCurrentUser()
  const userRef = computed(() => user.value && doc(db, `users/${user.value.uid}`))
  const userDoc = useDocument(userRef)

  function saveUser(from, object = userDoc.value) {
    console.log(`saving user from ${from}`)
    setDoc(userRef.value, object, { merge: true })
  }

  const userPrivateRef = computed(() => user.value && doc(db, `users/${user.value.uid}/private/data`))
  const userPrivateDoc = useDocument(userPrivateRef)

  return {
    userDocRef: userRef,
    userData() {
      return userDoc.value
    },
    is: {
      student: computed(() => userDoc.value?.type === 'student'),
      instructor: computed(() => userDoc.value?.type === 'instructor'),
      type: computed(() => userDoc.value?.type),
    },
    fn: {
      updateProfile: (profileObj) => {
        saveUser('updateProfile', profileObj)
      },
    },
    student: {
      fn: {
        likeUser: (userId) => {
          userPrivateDoc.value ||= {}
          userPrivateDoc.value.likedUsers ||= {}
          userPrivateDoc.value.likedUsers[userId] = !userPrivateDoc.value.likedUsers[userId]
          if (userPrivateDoc.value.likedUsers[userId]) {
            trackEvent('liked_instructor')
          } else {
            trackEvent('unliked_instructor')
          }
          setDoc(userPrivateRef.value, userPrivateDoc.value)
        },
      },
      likedUsers: computed(() => {
        return userPrivateDoc.value?.likedUsers || {}
      }),
    },
    instructor: {
      locations: computed(() => {
        return (userDoc.value?.instructor?.locations || []).sort((a, b) => a.name.localeCompare(b.name))
      }),
      lessonTypes: computed(() => {
        return (userDoc.value?.instructor?.lessonTypes || [])
      }),
      fn: {
        // selectLocations: function (locations) {
        //   userDoc.value.instructor ||= {}
        //   userDoc.value.instructor.locations = locations
        //   saveUser()
        // },
        addLessonType: function (lessonType) {
          lessonType.userId = userDoc.value.id
          lessonType.pricing = clearEmptyPricing(lessonType.pricing)
          lessonType.schedule = clearEmptySchedule(lessonType.schedule)

          // add or update lessonType to lessonTypes array
          userDoc.value.instructor ||= {}
          userDoc.value.instructor.lessonTypes ||= []
          const index = userDoc.value.instructor.lessonTypes.findIndex(({ uuid }) => uuid === lessonType.uuid)
          if (index === -1) {
            userDoc.value.instructor.lessonTypes.push(lessonType)
          } else {
            userDoc.value.instructor.lessonTypes[index] = lessonType
          }
          saveUser('addLessonType')
          trackEvent('schedule_updated', { pricing: Object.keys(lessonType.pricing).join(', ') })
        },
        removeLessonType: (lessonType) => {
          userDoc.value.instructor.lessonTypes ||= []
          const index = userDoc.value.instructor.lessonTypes.findIndex(({ uuid }) => uuid === lessonType.uuid)
          if (index !== -1) {
            userDoc.value.instructor.lessonTypes.splice(index, 1)
          }
          saveUser('removeLessonType')
          trackEvent('schedule_removed')
        },
      },
    },
  }
})
