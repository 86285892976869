import { collection, deleteDoc, doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { useCurrentUser, useDocument, useFirestore } from 'vuefire'

const db = useFirestore()

export class LessonInvitesRepository {
  constructor() {
    this.currentUser = useCurrentUser()
  }

  async createInviteLink() {
    console.log('creating invite link')
    // Generate a compact sortable timestamp prefix
    const timestamp = Math.floor((2000000000 - (new Date()).getTime() / 1000)).toString(36)
    // Generate a 4-character random string for uniqueness
    const randomPart = Math.random().toString(36).substring(2, 6)
    // Combine to create the custom ID
    const customId = `${timestamp}${randomPart}`

    const inviteData = {
      id: customId,
      instructorId: this.currentUser.value.uid,
      createdAt: serverTimestamp(),
    }
    const inviteRef = doc(collection(db, 'invites'), customId)
    await setDoc(inviteRef, inviteData)
    return inviteRef
  }

  getRef(id) {
    return doc(collection(db, 'invites'), id)
  }

  get(id) {
    return useDocument(this.getRef(id))
  }

  async update(invite) {
    await updateDoc(this.getRef(invite.id), invite)
  }

  async delete(invite) {
    await deleteDoc(this.getRef(invite.id))
  }
}
